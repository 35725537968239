@import url('https://fonts.googleapis.com/css2?family=Castoro+Titling&display=swap');

@tailwind base;
@tailwind utilities;

.display {
    font-family: "Instrument Serif";
}

.card {
    @apply block backdrop-blur-xl p-12 bg-white/20 border-8 border-white shadow-2xl rounded-sm;
}

.btn {
    @apply px-5 py-3 text-4xl inline-block bg-white shadow-2xl leading-none pb-[17px] text-gray-900 font-bold border-4 border-white rounded-sm;

    &:hover {
        @apply bg-white text-black cursor-pointer shadow-xl transition;
    }
}

@tailwind components;